.sitecore-form form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill);
  gap: 24px 48px;
  margin-bottom: 48px;
}

.sitecore-form form div.section {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  grid-column: span 2;
}

@media (min-width: 1024px) {
  .sitecore-form form div.section.half-width {
    grid-column: span 1;
  }
}
